import React from "react";

import { format } from "date-fns";
import ja from "date-fns/locale/ja";

// const useStyles = makeStyles(styles);

type Props = {
  date: string;
};

export default function Page(props: Props) {
  // const classes = useStyles();
  const { date } = props;
  // const [dateDiff, setDateDiff] = React.useState(0);

  // React.useEffect(() => {
  //   const diff = new Date().getTime() - new Date(date).getTime();
  //   const diffDay = diff / 1000 / 60 / 60 / 24;
  //   setDateDiff(diffDay);
  // }, [date]);
  return (
    <>
      {format(new Date(date), "yyyy.MM.dd", {
        locale: ja,
      })}
      {/* {dateDiff >= 1 &&
        format(new Date(date), "yyyy年MM月dd日 HH:mm", {
          locale: ja,
        })}
      {dateDiff < 1 &&
        formatDistanceToNow(new Date(date), {
          locale: ja,
        }) + "前"} */}
    </>
  );
}
